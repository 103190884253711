<template>
  <div class="row">
    <div class="col-12">
      <h5>Мерки</h5>
      <div class="row" v-for="(group, index) in form" :key="group.name">
        <div class="col-12">
          <h5 v-if="group.name !== 'default'">{{ group.name }}</h5>
        </div>
        <div v-for="(option, subkey) in group.options" class="col-3" :key="subkey">
          <div class="form-group">
            <label class="d-block">{{ option.name }}</label>
            <div>
              <input class="form-control" v-model="form[index].options[subkey].value" @click="update"/>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="col-8">
          <input class="form-control" v-model="groupName" placeholder="Название группы мерок">
        </div>
        <div class="col-4">
          <button class="btn btn-success" @click="addGroup" :disabled="!groupName">Добавить группу</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Measurements',
  props: ['options'],
  data() {
    return {
      form: [
        { name: 'default', options: null },
      ],
      groupName: '',
    };
  },
  mounted() {
    this.form[0].options = this.options;
  },
  methods: {
    addGroup() {
      if (this.groupName) {
        this.form.push({ name: this.groupName, options: this.options });
      }
      this.groupName = null;
    },
    update() {
      this.$emit('update', this.form);
    },
  },
};
</script>

<style scoped>

</style>
