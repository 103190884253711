<template>
  <div class="row">
    <div class="col-12">
      <label class="mb-1">{{ option.name }}</label>
      <div class="choice-section overflow-y-scroll">
        <div
            v-for="value in option.values"
            :class="['row choice-row', value.id === selectedOption.id ? 'active' : '']"
            @click="selectOption(value)"
            :key="value.id"
        >
          <div class="col-12">
            <div class="row">
              <div class="col font-weight-bold size-name">
                {{ value.name }} <span v-if="value.universal && value.universal !== value.name">({{ value.universal }})</span>
              </div>
              <div class="col">
                <small>EU: {{ value.eu }}</small><br>
                <small>US: {{ value.us }}</small>
              </div>
              <div class="col">
                <small>UK: {{ value.uk }}</small><br>
                <small>CA: {{ value.ca }}</small>
              </div>
              <div class="col">
                <small>DE: {{ value.de }}</small><br>
                <small>UA: {{ value.ua }}</small>
              </div>
              <div class="col">
                <small>IT: {{ value.it }}</small><br>
                <small>China: {{ value.china }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SizeSelector',
  props: ['option', 'slug', 'initValue'],
  data() {
    return {
      options: [],
      selectedOption: {},
    };
  },
  mounted() {
    this.options = this.option.values;

    if (this.initValue) {
      this.selectedOption = this.initValue;
    }
  },
  methods: {
    selectOption(value) {
      this.selectedOption = value;

      this.$emit('refresh', this.selectedOption, 'size');
    },
  },
};
</script>

<style lang="scss" scoped>
.choice-section {
  height: 300px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.choice-row {
  margin-right: 3px;
  cursor: pointer;
  padding: 5px 5px 5px 15px;
}

.choice-row:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}

.choice-row:hover {
  background-color: #f8f9fa;
}

.choice-row.active {
  background-color: #7367f0;
  color: white;
}

.size-name {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
</style>
